import { useLocation, useNavigate } from "react-router";

/**
 * Adds navigate and location as a prop to a component.
 * Used to access react router dom's `useNavigate` in class based components
 */
const withNavigation = (WrappedComponent) => (props) => {
    const navigate = useNavigate();
    const location = useLocation();

    return <WrappedComponent navigate={navigate} location={location} {...props} />;
  };

export default withNavigation;