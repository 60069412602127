import React from "react";
import { BrowserRouter, Route, Routes } from "react-router";
import AdComponent from "./components/AdComponent";
import OrderDoneComponent from "./components/OrderDoneComponent";
import PaymentComponent from "./components/PaymentComponent";
import { StoreConsumer } from "./ContextContainer/StoreContext";
import NotFound from "./components/NotFound";

import locales from "./Locales/Locales";
import WaitSpinner from "./components/WaitSpinner";

import i18next from "i18next";

i18next.init({
  lng: "nb",
  resources:
    locales
});

export default function Content() {
  return (
    <BrowserRouter basename="/avisrubrikk" >
      <StoreConsumer>
        {({ actions, publication, config, isLoading }) => {
          if (isLoading) {
            return <WaitSpinner />;
          }
          if (publication.locale && publication.locale !== i18next.language) {
            i18next.changeLanguage(publication.locale)
          }

          return (
              <Routes>
                <Route
                  path="/"
                  exact
                  element={ <AdComponent  />}
                />
                <Route
                  path="/payed/:adId"
                  exact
                  element={ <OrderDoneComponent  />}
                />
                <Route
                    path="/betaling"
                    exact
                    element={ <PaymentComponent  />}
                />
                <Route path="*" element={NotFound} />
              </Routes>
          );
        }}
      </StoreConsumer>
    </BrowserRouter>
  );
}
